<nav class="header-dashboard headerr__mainn">
  <div class="container-fluid">
    <div class="row">
      <!-- Company Logo-->
      <div class="company-logo">
        <!-- *ngIf="!isCandidate" -->
        <!--  [routerLink]="['/dashboard']" -->
        <a><img src="{{this.appSettings.imageUrl}}/img/my-logo.svg" alt="GIIS-Logo" /></a>
      </div>
      <div class="company-logo" *ngIf="isCandidate">
        <!-- *ngIf="isCandidate" -->
        <a><img src="{{this.appSettings.imageUrl}}/img/header/GSF-LOGO.svg" alt="GIIS-Logo" /></a>
      </div>
      <div class="nav-account row">
        <div class="nav-account__search">
          <input type="search" />
          <div class="nav-account__notification" [routerLink]="['/notification']"
            *ngIf="enableBellicon && activityData>0" style="cursor: pointer;">
            <img class="img-fluid" src="{{this.appSettings.imageUrl}}/img/header/notification.svg"
              alt="Notification-Icon" />
            <span class="notification-count ns1">{{activityData}}</span>
          </div>

          <!-- <button type="submit"><img class="img-fluid"
                            src="{{this.appSettings.imageUrl}}/img/header/search-btn.svg" alt="Search-Icon" /></button> -->
        </div>
        <div class="nav-account__profile pointer"  *ngIf="!hideDiv">
          <!-- <img class="avatar" src="{{path}}{{image}}" alt="" /> -->
          <img style="border-radius:50%; height: 50px;" src="{{this.appSettings.imageUrl}}/img/profile-icon.png"
            alt="profile icon">
          <div class="dropdown-content">
            <!-- <a href="https://careers-applicant.globalschools.com/login" target="_blank">Applicant Portal</a> -->
            <a href="https://gsf-career-applicant-staging.irepo.in" target="_blank">Applicant Portal</a>
            <!-- <a href="https://careers.globalschools.com/" target="_blank">Careers Website</a> -->
            <a href="https://gsf-career-staging.irepo.in" target="_blank">Careers Website</a>
            <a href="javascript:void(0)" (click)="logout()">Logout</a>
            <p class="version-name blue-color fs10 mb-2 d-flex justify-content-end px-3 fw600"> V.25.01.02 </p>
          </div>
          <span class="name">{{userName}}</span>
        </div>
      </div>
    </div>
  </div>
</nav>
<main class="header-side-nav-main pt-46">
  <div class="main-container">
    <aside class="side-nav scroll-container" id="side-nav scroll-container"  *ngIf="!hideDiv">
      <!-- <div class="new-icons-part new-icons-part-1" [routerLink]="'/dashboard'">
        <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/new-dashboard-icon.svg" alt="icon" />
        <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/new-dashboard-icon 1.svg" alt="icon" />
        <div class="new-icon-tooltip-part">
          <div class="tooltip-menu-items">
            <ul class="mt-0">
              <li class="single-list">Dashboard</li>
            </ul>
          </div>
        </div>
      </div> -->

      <!-- <ng-container *ngIf=" roleByUserName == 'HR Manager'"> -->
      <div class="new-icons-part new-icons-part-2 w-100 btn_hover_new" (mouseenter)="showSettingsMenu()" style="border: 0;">
        <!-- *ngIf="((hasRole2 || hasRole6) && hasRole9) || ((hasRole2 || hasRole6) && !hasRole9)" -->
        <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/new-reports-icon.svg" alt="icon" />
        <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/new-reports-icon 1.svg" alt="icon" />
        <div class="new-icon-tooltip-part">
          <h1> Home </h1>
          <div *ngIf="isMenuVisible" class="tooltip-menu-items" id="settingsMenu">
            <ul class="">
              <li (click)="onMenuItemClick('/dashboard-reports'); hideSettingsMenu(); removeScheduleStorageItem()"> Dashboard </li>
              <li (click)="onMenuItemClick('/reports'); hideSettingsMenu(); removeScheduleStorageItem()">Reports</li>
            </ul>
          </div>
        </div>
      </div>
    <!-- </ng-container> -->
      <!-- <div class="new-icons-part new-icons-part-3">
        <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/new-communications-icon.svg" alt="icon" />
        <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/new-communications-blue-icon.svg" alt="icon" />
        <div class="new-icon-tooltip-part">
          <h1> Communication </h1>
          <div class="tooltip-menu-items">
            <ul>
              <li>Conversation</li>
              <li [routerLink]="'/notification'">Notifications</li>
              <li [routerLink]="'/activitylog'">Activity Log</li>
            </ul>
          </div>
        </div>
      </div> -->


      <button class="new-icons-part new-icons-part-4 w-100 btn_hover_new" (mouseenter)="showSettingsMenu()" style="border: 0;"
      *ngIf="hasPermission(['TR Hr', 'TR Manager', 'TR Manager ',
              'Add Job', 'Edit Job', 'Job Approve-Reject', 'Close Job', 'Add Applicant', 'View Applicant', 'Manage Applicant', 'Edit Applicant',
              'Applicant Pool', 'Interviews', 'Bookmarks', 'IJP-HR', 'IJP-Employee'])">
        <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/new-manage-icon.svg" alt="icon" />
        <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/new-manage-icon 1.svg" alt="icon" />

        <div class="new-icon-tooltip-part">
          <h1> Manage </h1>
          <div *ngIf="isMenuVisible" class="tooltip-menu-items" id="settingsMenu">
            <ul>
              <!-- <li [routerLink]="'/dashboard'"> Dashboard </li> -->

              <!-- <li [routerLink]="'/trf'"> Talent Requisition </li>
              <li [routerLink]="'/tr-by-managers'"> TR by Managers </li> -->
              <!-- hasPermission('TR Hr', 'TR Manager', 'TR Manager ',
              'Add Job', 'Edit Job', 'Job Approve-Reject', 'Close Job', 'Add Applicant', 'View Applicant', 'Manage Applicant', 'Edit Applicant',
              'Applicant Pool', 'Interviews', 'Bookmarks', 'IJP-HR', 'IJP-Employee') -->
              <!-- <ng-container *ngIf="roleByUserName == 'HR Manager'"> -->
              <li (click)="onMenuItemClick('/trf'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission(['TR Hr', 'TR Manager', 'TR Manager '])"> Talent Requisition</li>
              <!-- <li (click)="onMenuItemClick('/trf'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('TR Hr')"> Talent Requisition (HR)</li>
              <li (click)="onMenuItemClick('/tr-by-managers'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission(['TR Manager', 'TR Manager '])"> Talent Requisition (MGR) </li> -->
              <li (click)="onMenuItemClick('/jobs'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission(['Add Job', 'Edit Job', 'Job Approve-Reject', 'Close Job'])">Jobs</li>
              <li (click)="onMenuItemClick('/candidates'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission(['Add Applicant', 'View Applicant', 'Manage Applicant', 'Edit Applicant'])">Applicants</li>
              <li (click)="onMenuItemClick('/enquiry'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('Applicant Pool')">Applicant Pool</li>
              <!-- </ng-container> -->
              <!-- <ng-container *ngIf="roleByUserName == 'HR Manager' || roleByUserName == 'Interviewer'"> -->
              <li (click)="onMenuItemClick('/interviews'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('Interviews')"> Interviews </li>
              <!-- </ng-container> -->
              <!-- <ng-container *ngIf="roleByUserName == 'HR Manager'"> -->
              <li (click)="onMenuItemClick('/bookmarks'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('Bookmarks')"> Bookmarks </li>
              <li (click)="onMenuItemClick('/job-listing'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('IJP-HR')"> IJP - HR </li>
              <li (click)="onMenuItemClick('/ijp-employee'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('IJP-Employee')"> IJP - Employee</li>
              <!-- <li (click)="onMenuItemClick('/ijp-employee'); hideSettingsMenu(); removeScheduleStorageItem()"
              *ngIf="hasPermission('')"> Internal Job Postings</li> -->
              <!-- </ng-container> -->
              </ul>

          </div>
        </div>
      </button>

      <!-- (click)="toggleSettingsMenu()" -->
       <!-- roleByUserName == 'HR Manager' &&  -->
       <ng-container *ngIf="hasPermission(['Add Role', 'Edit Role', 'Data Management',
       'Jobs Approval Hierarchy', 'Manage Jobs Approval Hierarchy(Add/Edit)', 'Approval Hierarchy(TR)', 'Manage Approval Hierarchy TR(Add/Edit)'])">
        <button class="new-icons-part new-icons-part-4 w-100 btn_hover_new" style="border: 0;"
         (mouseenter)="showSettingsMenu()" >
        <!-- *ngIf="((hasRole2 || hasRole6) && hasRole9) || ((hasRole2 || hasRole6) && !hasRole9)" -->
          <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/setting-2.svg" alt="icon" />
          <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/settings.svg" alt="icon"/>

          <div class="new-icon-tooltip-part"
               >
            <h1> Settings </h1>
            <div class="tooltip-menu-items" id="settingsMenu">
              <ul>
                <li (click)="onMenuItemClick('/settings/roles'); hideSettingsMenu(); removeScheduleStorageItem()"
                *ngIf="hasPermission(['Add Role', 'Edit Role'])">Role Management</li>
                <li (click)="onMenuItemClick('/system-fields'); hideSettingsMenu(); removeScheduleStorageItem()"
                *ngIf="hasPermission('Data Management')">Data Management</li>
                <li (click)="onMenuItemClick('/jah'); hideSettingsMenu(); removeScheduleStorageItem()"
                *ngIf="hasPermission(['Jobs Approval Hierarchy', 'Manage Jobs Approval Hierarchy(Add/Edit)'])">Approval Hierarchy (Jobs)</li>
                <li (click)="onMenuItemClick('/approval-hierarchy'); hideSettingsMenu(); removeScheduleStorageItem()"
                *ngIf="hasPermission(['Approval Hierarchy(TR)', 'Manage Approval Hierarchy TR(Add/Edit)'])">Approval Hierarchy (TR)</li>
              </ul>
            </div>
          </div>
        </button>
      </ng-container>

      <!-- <img class="grey-icon" src="{{this.appSettings.imageUrl}}/img/new-reports-icon.svg" alt="icon" />
      <img class="blue-icon" src="{{this.appSettings.imageUrl}}/img/new-reports-icon 1.svg" alt="icon" /> -->
      <!-- <div class="new-icons-part new-icons-part-4">
        <img class="grey-icon" src="{{this.appSettings.imageUrl}}/Images/grey-job-listing.svg" alt="icon" />
        <img class="blue-icon" src="{{this.appSettings.imageUrl}}/Images/blue-job-listing.svg" alt="icon" />


        <div class="new-icon-tooltip-part">
          <h1> IJP </h1>
          <div class="tooltip-menu-items">
            <ul>
              <li [routerLink]="'/performance-feedback-ijp'"> IJP Performance </li>
              <li [routerLink]="'/approval-stages-ijp'"> IJP Approver </li>
            </ul>
          </div>
        </div>
      </div> -->
      <!-- <span class="version-name blue-color fs12 mt-5 d-flex justify-content-center fw600"> V.24.10.25 </span> -->
    </aside>
  </div>
  <!-- <li class="new-ijp-link"> <a href="https://gsf-career-app-staging.irepo.in/gsf-dashboard/dashboard?token={{newDatatoken}}" target="_blank"> Dashboard </a> </li> -->
  <!-- <li class="new-ijp-link"> <a href="https://gsf-career-app-staging.irepo.in/gsf-dashboard/dashboard?token={{newDatatoken}}" target="_blank"> Dashboard </a> </li> -->

</main>
