import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashbordlayout',
  templateUrl: './dashbordlayout.component.html',
  styleUrls: ['./dashbordlayout.component.scss']
})
export class DashbordlayoutComponent implements OnInit {

  constructor() { }

  allRolesPermissions :any = [];
  ngOnInit(): void {
    this.allRolesPermissions = sessionStorage.getItem('All User Roles');
    // console.log(this.allRolesPermissions);

  }

  hasPermission(permission: string | string[]): boolean {
    if (Array.isArray(permission)) {
      return permission.some(requiredPermission =>
        this.allRolesPermissions.some(p => p.permissionName === requiredPermission)
      );
    } else {
      return this.allRolesPermissions.some(p => p.permissionName === permission);
    }
  }

}
